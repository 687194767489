import { compose, withHandlers } from 'recompose'
import { Loadable } from 'utils/components'
import { withRouter } from 'react-router-dom'
import  * as routes from 'constants/routes'
import { withStyles } from '@material-ui/styles'
import styles from './styles'

export default {
  routes: [{
    path: routes.ROOT_ROUTE,
    page: 'agent',
    exact: true
  }],
  component: compose(
    withRouter,
    withStyles(styles),
    withHandlers({
      
    }),
  // @ts-ignore
  )(Loadable({
    loader: () =>
      import(/* webpackChunkName: 'Agent' */ './Agent')
  }))
}