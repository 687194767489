import React from 'react'
import _ from 'lodash' // eslint-disable-line no-unused-vars
import  * as icons from 'constants/icons' // eslint-disable-line no-unused-vars
import Box from '@material-ui/core/Box'

function CenterBox(props) {
  const { classes, ...otherProps } = props // eslint-disable-line no-unused-vars
  
  return (
    <Box 
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={classes.box}
      {...otherProps}
    >
      <>{props.children}</>
    </Box>
  )
}

export default CenterBox
