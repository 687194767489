import { compose, withHandlers } from 'recompose'
import { Loadable } from 'utils/components'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import styles from './styles'

export default {
  component: compose(
    withRouter,
    withStyles(styles),
    withHandlers({
      
    }),
    
  // @ts-ignore
  )(Loadable({
    loader: () =>
      import(/* webpackChunkName: 'NotFound' */ './NotFound')
  }))
}