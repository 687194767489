import _ from 'lodash'

const initialState = {
	title: ''
}
const reducer = ((state = initialState, action) => {
	switch (action.type) {
		case 'SET_TITLE':
			state.title = _.get(action, 'title')
		break	
		case 'SET_CONTEXT':
			state.context = _.get(action, 'context')
		break	
		default: 
		
		break
	}
	return {
		...state
	}
})
export default reducer