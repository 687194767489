import React from 'react'
import _ from 'lodash' // eslint-disable-line no-unused-vars
import  * as icons from 'constants/icons' // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types' // eslint-disable-line no-unused-vars
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'

function MenuItemComponent(props) {
  const { classes, ...otherProps } = props // eslint-disable-line no-unused-vars
  
  const StyledMenuItem = withStyles({
    root: {
      backgroundColor: _.get(props, 'color', 'white') 
    },
  })(MenuItem)
  
  // console.log('options :', options);
  return (
    <StyledMenuItem {...otherProps} >
      <>{props.children}</>
    </StyledMenuItem>
  )
}

MenuItemComponent.propTypes = {
  formTextField: PropTypes.object
}

export default MenuItemComponent
