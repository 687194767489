import React from 'react'
import _ from 'lodash' // eslint-disable-line no-unused-vars
import  * as icons from 'constants/icons' // eslint-disable-line no-unused-vars
import ListSubheader from '@material-ui/core/ListSubheader'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'

function ListSubheaderComponent({ classes, text, icon, children }) {
  
  return (
    <ListSubheader color="inherit" component="div" className={classes.box}>
      {text && 
        <Box 
          display="flex"
          // justifyContent="center"
          // alignItems="center"
          className={classes.box}
        >
          {icon &&
            <span className={classes.icon}>{icon}&nbsp;</span>
          }
          <span className={classes.text}>{text}</span>
          <Divider variant="fullWidth" component="hr" />
        </Box>
      }
      <>{children}</>
    </ListSubheader>
  )
}

export default ListSubheaderComponent
