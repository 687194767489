import LoginModal from './LoginModal'
import { withStyles } from '@material-ui/styles'
import styles from './styles'
import { compose, withHandlers } from 'recompose'

export default compose(
  withStyles(styles),
  withHandlers({
    
  }),
)(LoginModal)
