
const styles = (theme) => ({
  ...theme.global,
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  panel: {
    ...theme.flexColumnCenter,
    justifyContent: 'center',
    flexGrow: 1,
    padding: '1.25rem',
    minWidth: '250px',
    minHeight: '270px'
  },
})
export default styles
