import normalize from 'normalize-path'

export const ROOT_ROUTE = normalize(`/`)
export const AGENT_ACCOUNT_ROUTE = normalize(`${ROOT_ROUTE}/account`)
export const ADMIN_ROUTE = normalize(`${ROOT_ROUTE}/admin`)
export const ADMIN_ACCOUNT_ROUTE = normalize(`${ADMIN_ROUTE}/account`)
export const EDIT_ACCOUNT_ROUTE = normalize(`${ADMIN_ACCOUNT_ROUTE}/edit`)
export const ADMINISTRATION_ROUTE = normalize(`${ADMIN_ROUTE}/administration`)
export const SYNC_ROUTE = normalize(`${ADMINISTRATION_ROUTE}/sync`)
export const LOGS_ROUTE = normalize(`${ADMINISTRATION_ROUTE}/logs`)
export const STATUSES_ROUTE = normalize(`${ADMINISTRATION_ROUTE}/statuses`)
export const MARKETS_ROUTE = normalize(`${ADMINISTRATION_ROUTE}/markets`)
export const LOCATIONS_ROUTE = normalize(`${ADMINISTRATION_ROUTE}/locations`)
export const ADDRESSES_ROUTE = normalize(`${ADMINISTRATION_ROUTE}/addresses`)
export const PRINTING_ROUTE = normalize(`${ADMINISTRATION_ROUTE}/printing`)
export const TEMPLATES_ROUTE = normalize(`${ADMINISTRATION_ROUTE}/templates`)
export const MAILED_ROUTE = normalize(`${ADMINISTRATION_ROUTE}/mailed`)
export const ADMINS_ROUTE = normalize(`${ADMINISTRATION_ROUTE}/admins`)
export const PARTNERS_ROUTE = normalize(`${ADMIN_ROUTE}/partners`)
export const WELCOME_ROUTE = normalize(`${ADMIN_ROUTE}/`)
export const ORDERS_ROUTE = normalize(`${ADMIN_ROUTE}/orders`)
export const SEARCH_ROUTE = normalize(`${ORDERS_ROUTE}/search`)