import { combineReducers } from 'redux'
// @ts-ignore
import { reducer as firebaseReducer } from 'react-redux-firebase'
import { reducer as notificationsReducer } from 'modules/notification'
import { reducer as formReducer } from 'redux-form'
import navigationReducer from './navigation/reducer'
import showReducer from './show/reducer'
import ordersReducer from './orders/reducer'

export function makeRootReducer(asyncReducers) {
  return combineReducers({
    // Add sync reducers here
    firebase: firebaseReducer,
    form: formReducer,
    notifications: notificationsReducer,
    navigation: navigationReducer,
    show: showReducer,
    orders: ordersReducer,
    ...asyncReducers
  })
}

export function injectReducer(store, { key, reducer }) {
  store.asyncReducers[key] = reducer // eslint-disable-line no-param-reassign
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
