 import React from 'react'
 import _ from 'lodash' // eslint-disable-line no-unused-vars
import  * as icons from 'constants/icons' // eslint-disable-line no-unused-vars
import { Switch, Route } from 'react-router-dom'
import { useDispatch,  useSelector } from 'react-redux'  // eslint-disable-line no-unused-vars
import Navbar from 'components/nav/Navbar'
import { Notifications } from 'modules/notification'
import { renderRoutes } from 'utils/router'
import LoadingSpinnerModal from 'components/popup/LoadingSpinnerModal'
import LoginModal from 'components/login/LoginModal'
import LoadingProgress from 'components/popup/LoadingProgress'
import Offline from 'components/general/Offline'
import Admin from './Admin'
import Agent from './Agent'
import Empty from './Empty'
import NotFound from './NotFound'
import { isLoaded } from 'react-redux-firebase'

function Root({ classes, match, store }) {
  const auth = useSelector(state => _.get(state, `firebase.auth`))
  const show = useSelector(state => _.get(state, 'show'))
  
  React.useEffect(() => {
    if(_.get(window, 'location.hostname') === 'printmor.wasp-soft.com') {
        window.open(_.get(window, 'location.href').replace(_.get(window, 'location.hostname'), 'app.myprintmor.com'), '_self')
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps 
  
  return (
    <div>
      <Navbar />
      <Offline />
      <div className={classes.routes}>
        {isLoaded(auth) && !_.get(auth, 'uid') &&
          <div className={classes.full}>
            <LoginModal />
            <Switch>
              <Route component={Empty.component} />
            </Switch>
            <LoadingProgress/>
          </div>
        }
        {auth.isLoaded && auth.uid && 
          <Switch>
            {renderRoutes([
              Admin,
              Agent,
            ], match, { store })}
            <Route component={NotFound.component} />
          </Switch>
        }
      </div>
      <Notifications />
      {_.get(show, 'spinner') &&
        <LoadingSpinnerModal/>
      }
    </div>
  )
}
export default Root
