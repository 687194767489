import _ from 'lodash' // eslint-disable-line no-unused-vars
import  * as icons from 'constants/icons' // eslint-disable-line no-unused-vars
import { getFirebase, isEmpty } from 'react-redux-firebase'
import  * as refs from 'constants/firebaseRefs'
import store from 'store'
import timestamp  from 'unix-timestamp'
import sha256 from 'js-sha256'

export const saveSuggestion = async (update) => {
  const firebase = getFirebase()
  // if(_.get(update, 'market')) {
  //   let id = sha256(_.get(update, 'market')).substring(0, 8)
  //   await firebase.set(`/${refs.MARKETS_REF}/${id}/market`, _.get(update, 'market')).catch((error) => {
  //     console.error(error)
  //     // @ts-ignore
  //     store.dispatch({
  //       type: 'HIDE_SPINNER'
  //     })
  //     throw new Error(error.message || 'Error saving data') 
  //   })
  // }
  if(_.get(update, 'advertiser')) {
    let id = sha256(_.get(update, 'advertiser')).substring(0, 8)
    await firebase.set(`/${refs.ADVERTISERS_REF}/${id}`, _.get(update, 'advertiser')).catch((error) => {
      console.error(error)
      // @ts-ignore
      store.dispatch({
        type: 'HIDE_SPINNER'
      })
      throw new Error(error.message || 'Error saving data') 
    })
  }
  if(_.get(update, 'contacts')) {
    if(_.isArray(_.get(update, 'contacts'))) {
      await _.forEach(_.get(update, 'contacts'), async (contact) => {
        if(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(_.get(contact, 'email'))) {
          let id = sha256(_.get(contact, 'email')).substring(0, 8)
          await firebase.set(`/${refs.CONTACTS_REF}/${id}`, _.get(contact, 'email')).catch((error) => {
            console.error(error)
            // @ts-ignore
            store.dispatch({
              type: 'HIDE_SPINNER'
            })
            throw new Error(error.message || 'Error saving data') 
          })
        }
      })
    }
  }
}

export const updateOrder = async (id, update, then) => {
  const firebase = getFirebase()
  
  if (!id) {
    throw new Error('Missing ID')
  }
  if (isEmpty(update)) {
    throw new Error('Missing data')
  }
  // @ts-ignore
  store.dispatch({
    type: 'SHOW_SPINNER'
  })
  await saveSuggestion(update)
  return firebase.update(`/${refs.ORDERS_REF}/${id}`, update).then(() => {
    // @ts-ignore
    store.dispatch({
      type: 'HIDE_SPINNER'
    })
  }).then(() => {
     if(then) {
      then()
     }
  }).catch((error) => {
    console.error(error)
    // @ts-ignore
    store.dispatch({
      type: 'HIDE_SPINNER'
    })
    throw new Error(error.message || 'Error saving data') 
  })
}

export const archiveOrder = (id, order, then) => {
  const firebase = getFirebase()
  
  if (!id) {
    throw new Error('Missing ID')
  }
  if (isEmpty(order)) {
    throw new Error('Missing data')
  }
  order.archived_date = Math.round(timestamp.now(0))
  // @ts-ignore
  store.dispatch({
    type: 'SHOW_SPINNER'
  })
  return firebase.set(`/${refs.ARCHIVES_REF}/${id}`, order).then(() => {
    return firebase.remove(`/${refs.ORDERS_REF}/${id}`)
  }).then(() => {
    // @ts-ignore
    store.dispatch({
      type: 'HIDE_SPINNER'
    })
  }).then(() => {
     if(then) {
      then()
     }
  }).catch((error) => {
    console.error(error)
    // @ts-ignore
    store.dispatch({
      type: 'HIDE_SPINNER'
    })
    throw new Error(error.message || 'Error archiving data') 
  })
}

export const unarchiveOrder = (id, order, then) => {
  const firebase = getFirebase()
  
  if (!id) {
    throw new Error('Missing ID')
  }
  if (isEmpty(order)) {
    throw new Error('Missing data')
  }
  // @ts-ignore
  store.dispatch({
    type: 'SHOW_SPINNER'
  })
  return firebase.set(`/${refs.ORDERS_REF}/${id}`, order).then(() => {
    return firebase.remove(`/${refs.ARCHIVES_REF}/${id}`)
  }).then(() => {
    // @ts-ignore
    store.dispatch({
      type: 'HIDE_SPINNER'
    })
  }).then(() => {
     if(then) {
      then()
     }
  }).catch((error) => {
    console.error(error)
    // @ts-ignore
    store.dispatch({
      type: 'HIDE_SPINNER'
    })
    throw new Error(error.message || 'Error restoring data') 
  })
}
