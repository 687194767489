import  * as refs from 'constants/firebaseRefs'

export const env = 'local'

// Config for firebase
export const firebaseConfig = {
  apiKey: 'AIzaSyD53TjSx5hirmT9LpWEwXr2VFypWliafFU',
  authDomain: 'sage-tribute-294518.firebaseapp.com',
  databaseURL: 'https://sage-tribute-294518.firebaseio.com',
  projectId: 'sage-tribute-294518',
  storageBucket: 'sage-tribute-294518.appspot.com',
  messagingSenderId: '483310648885',
  appId: '1:483310648885:web:162c11e38ea1120ddf7b7c'
}

// Config to override default reduxFirebase config in store/createStore
// which is not environment specific.
// For more details, visit http://react-redux-firebase.com/docs/api/enhancer.html
export const reduxFirebase = {
  userProfile: 'users',
  enableLogging: false, // enable/disable Firebase Database Logging
  profileFactory: (userData, profileData) => { // how profiles are stored in database
    // console.log('profileData :', profileData);
    // console.log('userData :', userData);
    profileData.uid = userData.uid
    if(userData.providerData) {
      profileData.admin = userData.uid
    }
    else {
      profileData.agent = userData.uid
    }
    return profileData
  },
  profileParamsToPopulate: [{ 
    child: 'admin', 
    root: refs.ADMINS_REF
  }],
}

export default {
  env,
  firebaseConfig,
  reduxFirebase
}
