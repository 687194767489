import React from 'react'
import _ from 'lodash' // eslint-disable-line no-unused-vars
import  * as icons from 'constants/icons' // eslint-disable-line no-unused-vars
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
// import { withStyles } from '@material-ui/core/styles'

function ListItemComponent(props) {
  const { classes, ...otherProps } = props // eslint-disable-line no-unused-vars
  
  // const StyledListItem = withStyles({
  //   ...Object.assign(otherProps.styles || {})
  // })(ListItem)
  
  return (
    <ListItem {...otherProps} color="inherit" component="div" className={classes.item}>
      {_.get(props, 'divider') !== false &&
        <Divider/>
      }
      <>{props.children}</>
    </ListItem>
  )
}

export default ListItemComponent
