
const styles = (theme) => ({
  ...theme.global,
  progress: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '32vh',
    width: '100%',
  },
  linear: {
    height: 10,
  }
})
export default styles
