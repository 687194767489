
const styles = (theme) => ({
  ...theme.global,
  box: {
    ...theme.flexColumnCenter,
    justifyContent: 'flex-start',
    flexGrow: 1,
    height: '100%',
    width: '100%',
    margin: '.2rem'
  },
  button: {
    marginRight: 10,
    fontWeight: 'bold',
    borderRadius: 10
  },
})
export default styles
