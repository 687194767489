import React from 'react'
import _ from 'lodash' // eslint-disable-line no-unused-vars
import  * as icons from 'constants/icons' // eslint-disable-line no-unused-vars
import { useDispatch,  useSelector } from 'react-redux'  // eslint-disable-line no-unused-vars

import GoogleButton from 'react-google-button'
import Divider from '@material-ui/core/Divider'
import { getFirebase } from 'react-redux-firebase'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListSubheader from 'components/list/ListSubheader'
import ListItem from 'components/list/ListItem'
import { useNotifications } from 'modules/notification'
import LoginForm from './LoginForm'
import { useHistory, useLocation } from 'react-router-dom'

function LoginModal({ classes }) {
  const dispatch = useDispatch() // eslint-disable-line no-unused-vars
  const location = useLocation() // eslint-disable-line no-unused-vars
  const query = new URLSearchParams(_.get(location, 'search')) // eslint-disable-line no-unused-vars
  const history = useHistory() // eslint-disable-line no-unused-vars
  const firebase = getFirebase()
  
  const { showError } = useNotifications()
  
  const onSubmitFail = (errors, dispatch, err) => {
    showError(errors ? 'Form Invalid' : err.message || 'Error')
  }
  
  const handleGoogleLogin = () => {
    firebase.login({ 
      provider: 'google', 
      type: 'popup' 
    }).then((data) => {
      // console.log('data :', data)
      sendNewAdmin(_.get(data, 'profile.uid'))
    }).catch((error) => {
      showError(error.message)
    })
  }
  
  const handleEmailLogin = (creds) => {
    firebase.login(creds).then((data) => {
      console.log('data :', data)
    }).catch((error) => {
      showError(error.message)
    })
  }
  
  const sendNewAdmin = async (admin) => {
    try {
      // @ts-ignore
      let sent =  await firebase.functions().httpsCallable('sendTemplatedMessage')({
        template: 'new-admin-login',
        render: {
          admin: admin
        }
      }).catch(error => {
        console.error(error)
      })
      console.log('sent :', sent)
    }
    catch(error) {
      console.error(error)
    }
  }

  let allowEmailLogin = true
  let allowGoogleLogin = true
  if(_.get(window, 'location.host', '').startsWith('admin') || _.get(window, 'location.pathname', '').startsWith('/admin')) {
    allowEmailLogin = false
  }
  else {
    allowGoogleLogin = false
  }
  
  return (
    <Modal
      className={classes.modal}
      open={true}
    >
      <div className={classes.box}>
        <Paper className={classes.panel}>
          <List subheader={
            <ListSubheader 
              text={`You can login here...`} 
              icon={(<icons.VpnKeyIcon/>)}
            />
          }>
            {allowEmailLogin &&
              <LoginForm onSubmit={handleEmailLogin} onSubmitFail={onSubmitFail} />
            }
            {allowGoogleLogin &&
              <>
                <ListItem alignItems="flex-start">
                  <GoogleButton onClick={handleGoogleLogin} data-test="google-auth-button" />
                  <Divider/>
                </ListItem>
                <ListSubheader >&nbsp;</ListSubheader>
              </>
            }
          </List>
        </Paper>
      </div>
    </Modal>
  )
}

export default LoginModal
