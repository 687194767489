import React from 'react'
import _ from 'lodash' // eslint-disable-line no-unused-vars
import  * as icons from 'constants/icons' // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types' // eslint-disable-line no-unused-vars
import LinearProgress from '@material-ui/core/LinearProgress'

function LoadingProgress({ classes }) {
  return (
    <div className={classes.progress}>
      <LinearProgress className={classes.linear} color="primary" />
    </div>
  )
}

LoadingProgress.propTypes = {
  size: PropTypes.number
}

export default LoadingProgress
