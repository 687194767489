import Offline from './Offline'
import { compose, withHandlers } from 'recompose'
import { withStyles } from '@material-ui/styles'
import styles from './styles'

export default compose(
  withStyles(styles),
  withHandlers({
    
  }),
)(Offline)
