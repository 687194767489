export const ADMINS_REF = 'admins'
export const PARTNERS_REF = 'partners'
export const AGENTS_REF = 'agents'
export const USERS_REF = 'users'
export const ORDERS_REF = 'orders'
export const HISTORY_REF = 'history'
export const ARCHIVES_REF = 'archives'
export const STATUSES_REF = 'statuses'
export const LOCATIONS_REF = 'locations'
export const TEMPLATES_REF = 'templates'
export const ADDRESSES_REF = 'addresses'
export const PRINTING_REF = 'printing'
export const PRINTING_PAGES_REF = 'pages'
export const MAILED_REF = 'mailed'
export const MARKETS_REF = 'markets'
export const CONTACTS_REF = 'contacts'
export const ADVERTISERS_REF = 'advertisers'