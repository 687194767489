export const FILTER_FORM = 'filter'
export const ORDER_OPTIONS = 'options'
export const ORDER_COLUMNS = 'columns'
export const SORT_FORM = 'sort'
export const ACCOUNT_FORM = 'account'
export const ADMIN_FORM = 'admin'
export const PARTNER_FORM = 'partner'
export const PARTNER_DEPOSIT_FORM = 'partner_deposit'
export const AGENT_FORM = 'agent'
export const LOGIN_FORM = 'login'
export const PRODUCT_FORM = 'product'
export const PRODUCTS_FORM = 'products'
export const POST_PRODUCTION_FORM = 'postproduction'
export const PROOFS_FORM = 'proofs'
export const ORDER_FORM = 'order'
export const SHIPPING_ADDRESS = 'address'
export const SHIPPING_VENDOR = 'vendor'
export const SHIPPING_PACKAGES_FORM = 'packages'
export const SHIPPING_RATES_FORM = 'rates'
export const SHIPPING_LABELS_FORM = 'labels'
export const SHIPPING_UNITS_FORM = 'units'
export const SHIPPING_QUOTES_FORM = 'quotes'
export const SHIPPING_BOOK_FORM = 'book'
export const INSTALLING_FORM = 'installing'
export const STATUSES_FORM = 'statuses'
export const MARKETS_FORM = 'markets'
export const MARKET_CONTACTS_FORM = 'marketcontacts'
export const PRINTING_FORM = 'printing'
export const TEMPLATES_FORM = 'templates'
export const ADMIN_LOCATION_FROM = 'adminlocation'
