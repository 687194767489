
const styles = (theme) => ({
  ...theme.global,
  form: {
    margin: theme.spacing(1),
  },
  select: {
    color: theme.palette.background.paper,
    borderColor: theme.palette.background.paper,
    backgroundColor: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '1.125rem',
    "&:before": {
      borderColor: theme.palette.background.paper
    }
  },
  carrot: {
    color: theme.palette.background.paper
  },
  item: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    backgroundColor: theme.palette.background.paper,
    marginRight: 10,
    fontSize: 40
  },
})
export default styles
