import Root from './Root'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import styles from './styles'
import { compose, withHandlers } from 'recompose'

export default compose(
  withStyles(styles),
  withRouter,
  withHandlers({
    
  }),
)(Root)
