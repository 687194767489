
const styles = (theme) => ({
  ...theme.global,
  box: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15,
    alignItems: 'center',
    height: '90vh',
  }
})
export default styles
