import React from 'react'
import _ from 'lodash' // eslint-disable-line no-unused-vars
import  * as icons from 'constants/icons' // eslint-disable-line no-unused-vars
import { useDispatch,  useSelector } from 'react-redux'  // eslint-disable-line no-unused-vars
import { isEmpty, useFirebaseConnect, getFirebase } from 'react-redux-firebase'
import  * as refs from 'constants/firebaseRefs'
import LoadingSpinner from 'components/popup/LoadingSpinner'
import { useHistory, useLocation } from 'react-router-dom'
import MenuItem from 'components/nav/MenuItem'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import { useNotifications } from 'modules/notification'
import IconButton from '@material-ui/core/IconButton'
import theme from 'theme'
import Avatar from '@material-ui/core/Avatar'
import isUrl from 'is-url'
import  * as routes from 'constants/routes'

function LocationPreferenceMenu({ classes }) {
  const dispatch = useDispatch() // eslint-disable-line no-unused-vars
  const location = useLocation() // eslint-disable-line no-unused-vars
  const query = new URLSearchParams(_.get(location, 'search')) // eslint-disable-line no-unused-vars
  const history = useHistory() // eslint-disable-line no-unused-vars
  const profile = useSelector(state => _.get(state, `firebase.profile`))
  const admin = useSelector(state => _.get(state, `firebase.data.admins.${_.get(profile, 'uid', '-')}`))
  const { showError } = useNotifications()
  const firebase = getFirebase()
  
  const setLocationPreference = (location) => {
    let update  = profile
    _.set(update, `preferences.location`, location)
    return firebase.updateProfile(update).then(() => {
      // window.location.reload(false)
      history.push(routes.ORDERS_ROUTE)
    }).catch(error => {
      console.error('Error updating profile', error.message || error) // eslint-disable-line no-console
      showError(`Error updating profile: ${error.message || error}`)
      return Promise.reject(error)
    })
  }
  
  useFirebaseConnect(() => [{
    path: refs.LOCATIONS_REF
  }])
  const locations = useSelector(state => _.get(state, `firebase.ordered.${refs.LOCATIONS_REF}`))
  const logo = useSelector(state => _.get(state, `firebase.data.${refs.LOCATIONS_REF}.${_.get(profile, 'preferences.location', _.findKey(_.get(admin, 'locations'), { enabled: true }))}.logo`))
  
  let options = []
  if(!isEmpty(locations)) { 
    Object.keys(locations).map((index) => {
      let location = _.get(locations, `${index}`)
      if(_.get(admin, `locations.${_.get(location, `key`)}.enabled`)) {
        options.push({
          value: _.get(location, 'key'),
          desc: _.get(location, 'value.name'),
          icon: (
            <icons.LocationOnIcon/>
          )
        })
      }
      return null
    })
  }
  // console.log('options :', options)
  
  React.useEffect(() => {
    if(_.get(profile, 'preferences.location') && !_.get(admin, `locations.${_.get(profile, 'preferences.location')}.enabled`)) {
      setTimeout(() => {
        setLocationPreference(_.findKey(_.get(admin, 'locations'), { enabled: true }))
      })
    }
  }) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <FormControl className={classes.form}  >
        {_.size(locations) === 0 &&
          <LoadingSpinner size={30}/>
        }
        {_.size(options) > 0 &&
          <Select
            value={_.get(profile, 'preferences.location', _.findKey(_.get(admin, 'locations'), { enabled: true }))}
            classes={{
              select: classes.select,
              icon: classes.carrot
            }}
            style={{ icon: {} }}
            onChange={(event) => {
              if(_.get(event, 'target.value')) {
                setLocationPreference(_.get(event, 'target.value'))
              }
            }}
          >
            {options && options.map((option, index) => (
              <MenuItem 
                className={classes.item}
                // color={theme.palette.background.paper} 
                value={_.get(option, 'value', '')} 
                key={index}
              >
                <IconButton size={'small'}>
                  <icons.LocationOnIcon style={{ fontSize: 25, padding: 0, margin: 0, color: theme.palette.secondary.main }}/>&nbsp;
                </IconButton>
                  {_.get(option, 'desc', '')}
              </MenuItem>
            ))}
          </Select>
        }
        {_.size(locations) > 0 && _.size(options) === 0 &&
          <IconButton size={'small'}>
            <icons.LocationOffIcon style={{ fontSize: 25, padding: 0, margin: 0, color: theme.palette.error.main }}/>&nbsp;
            <span style={{ color: theme.palette.error.main }}><b>{'LOCATION NOT DEFINED'}</b></span>
          </IconButton>
        }
      </FormControl>
      {isUrl(logo) && 
        <Avatar 
          variant={'circular'}
          alt={logo} 
          src={logo}
          className={classes.avatar}
        />
      }
    </>
  )
}

export default LocationPreferenceMenu
