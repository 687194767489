import React from 'react'
import _ from 'lodash' // eslint-disable-line no-unused-vars
import  * as icons from 'constants/icons' // eslint-disable-line no-unused-vars
import ReactDOM from 'react-dom'
import { initScripts } from './utils'
import store from './store'
import { version } from '../package.json'
import { env } from './config'
import App from './App'
import './index.css'
import * as serviceWorker from './serviceWorker'

// import * as serviceWorker from './serviceWorker'

// Window Variables
// ------------------------------------
window.version = version
window.env = env
initScripts()

ReactDOM.render(
  <App store={store} />,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
