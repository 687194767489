import LoginForm from './LoginForm'
import { withStyles } from '@material-ui/styles'
import styles from './styles'
import PropTypes from 'prop-types' // eslint-disable-line no-unused-vars
import { compose, withHandlers, setPropTypes } from 'recompose'
import { reduxForm } from 'redux-form'
import  * as forms from 'constants/forms'

export default compose(
  withStyles(styles),
  setPropTypes({
    // onSubmit: PropTypes.func.isRequired
  }),
  reduxForm({ form: forms.LOGIN_FORM }),
  withHandlers({
    
  }),
)(LoginForm)
