import { compose, withHandlers } from 'recompose'
import { Loadable } from 'utils/components'
import { withRouter } from 'react-router-dom'
import  * as routes from 'constants/routes'
import { withStyles } from '@material-ui/styles'
import styles from './styles'

export default {
  routes: [{
    path: routes.ADMIN_ROUTE
  }],
  component: compose(
    withStyles(styles),
    withRouter,
    withHandlers({
      
    }),
  // @ts-ignore
  )(Loadable({
    loader: () =>
      import(/* webpackChunkName: 'Admin' */ './Admin')
  }))
}