import React from 'react'
import _ from 'lodash' // eslint-disable-line no-unused-vars
import  * as icons from 'constants/icons' // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types' // eslint-disable-line no-unused-vars
import TextField from '@material-ui/core/TextField'

function FormTextField({ classes, placeholder, label, input,  meta: { touched, invalid, error }, ...custom }) {
    
  return (
    <TextField
      placeholder={placeholder || 'Type here...'}
      label={label}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  )
}

FormTextField.propTypes = {
  formTextField: PropTypes.object
}

export default FormTextField
