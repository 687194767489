import React from 'react'
import clsx from 'clsx'
import _ from 'lodash' // eslint-disable-line no-unused-vars
import  * as icons from 'constants/icons' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { useDispatch, useSelector } from 'react-redux'
import { isLoaded, isEmpty, getFirebase } from 'react-redux-firebase'
import  * as routes from 'constants/routes'
import { Helmet } from 'react-helmet'
import CssBaseline from '@material-ui/core/CssBaseline'
import { BrowserView } from 'react-device-detect'
import theme from 'theme'
import LocationPreferenceMenu from 'components/location/LocationPreferenceMenu'
import { useLocation } from 'react-router-dom'

function Navbar({ classes }) {
  const dispatch = useDispatch() // eslint-disable-line no-unused-vars
  const auth = useSelector(state => _.get(state, `firebase.auth`))
  const title = useSelector(state => _.get(state, 'navigation.title', 'Printmor'))
  const context = useSelector(state => _.get(state, 'navigation.context'))
  const sidebar = useSelector(state => _.get(state, 'show.sidebar'))
  const firebase = getFirebase()
  const profile = useSelector(state => _.get(state, `firebase.profile`))
  const admin = useSelector(state => _.get(state, `firebase.data.admins.${_.get(profile, 'uid', '-')}`))
  const location = useLocation() // eslint-disable-line no-unused-vars
  const query = new URLSearchParams(_.get(location, 'search')) // eslint-disable-line no-unused-vars
  
  return (
    <>  
      <CssBaseline />
      <div className={classes.toolbar}>
        &nbsp;
      </div>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: sidebar
        })}
        style={{
          zIndex: sidebar ? _.get(theme, 'zIndex.drawer', 0) - 1 : _.get(theme, 'zIndex.drawer', 0) + 1
        }}
      >
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Toolbar>
          <IconButton
            size={'small'}
            color="inherit"
            aria-label="open drawer"
            onClick={() => dispatch({ type: 'SHOW_SIDEBAR' })}
            edge="start"
            className={clsx(classes.menuIconButton, {
              [classes.hide]: sidebar,
            })}
          >
            <icons.MenuOpenIcon />
          </IconButton>
          <Typography 
            className={classes.title}
            variant="h6"
            noWrap
          >
            &nbsp;
            {!sidebar && title}
          </Typography>
          {context === 'admin' && _.get(admin, 'enabled') &&
            <>
              <LocationPreferenceMenu/>
              &nbsp;|&nbsp;
              <IconButton
                size={'small'}
                className={classes.link}
                component={Link}
                to={routes.ADMIN_ROUTE}
              >
                <icons.HomeIcon/>&nbsp;
                <BrowserView>
                  Home
                </BrowserView>
              </IconButton>
              &nbsp;|&nbsp;
              <IconButton
                size={'small'}
                className={classes.link}
                component={Link}
                to={`${routes.ORDERS_ROUTE}${_.get(profile, 'preferences.searching.url', '').replace('ref=archives', 'ref=orders')}`}
              >
                <icons.PlaylistAddCheckIcon/>&nbsp;
                <BrowserView>
                  Orders
                </BrowserView>
              </IconButton>
              {_.get(admin, 'partners.edit', false) &&
                <>
                &nbsp;|&nbsp;
                <IconButton
                  size={'small'}
                  className={classes.link}
                  component={Link}
                  to={routes.PARTNERS_ROUTE}
                >
                  <icons.BusinessIcon/>&nbsp;
                  <BrowserView>
                    Clients
                  </BrowserView>
                </IconButton>
                </>
              }
              &nbsp;|&nbsp;
              <IconButton
                size={'small'}
                className={classes.link}
                component={Link}
                to={routes.ADMINISTRATION_ROUTE}
              >
                <icons.SecurityIcon/>&nbsp;
                <BrowserView>
                  Administration
                </BrowserView>
              </IconButton>
            </>
          }
          {context !== 'admin' && _.get(admin, 'enabled') &&
            <>
              &nbsp;|&nbsp;
              <IconButton
                size={'small'}
                className={classes.link}
                component={Link}
                to={routes.ADMIN_ROUTE}
              >
                <icons.SupervisorAccountIcon/>&nbsp;
                <BrowserView>
                  Admin
                </BrowserView>
              </IconButton>
            </>
          }
          {(isLoaded(auth) && !isEmpty(auth)) && 
            // <AccountMenu />
            <>
              &nbsp;|&nbsp;
              <IconButton
                size={'small'}
                className={classes.link}
                onClick={() => firebase.logout()}
              >
                <icons.ExitToAppIcon/>&nbsp;
                <BrowserView>
                  Logout
                </BrowserView>
              </IconButton>
            </>
          }
          {(isLoaded(auth) && isEmpty(auth)) && 
            <IconButton
              size={'small'}
              className={classes.link}
              component={Link}
              to={routes.ROOT_ROUTE}>
              Login
            </IconButton>
          }
        </Toolbar>
      </AppBar>
    </>
  )
}

export default Navbar
