import _ from 'lodash' // eslint-disable-line no-unused-vars
import  * as icons from 'constants/icons' // eslint-disable-line no-unused-vars
import { updateOrder, archiveOrder, unarchiveOrder } from './actions'

const initialState = {}
const reducer = ((state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_ORDER':
			setTimeout(() => {
        updateOrder(action.id, action.update, action.then)
      })
		break	
		case 'ARCHIVE_ORDER':
			setTimeout(() => {
        archiveOrder(action.id, action.order, action.then)
      })
		break	
		case 'UNARCHIVE_ORDER':
			setTimeout(() => {
        unarchiveOrder(action.id, action.order, action.then)
      })
		break	
		default: 
		
		break
	}
	return {
		...state
	}
})
export default reducer