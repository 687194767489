import React from 'react'
import _ from 'lodash' // eslint-disable-line no-unused-vars
import  * as icons from 'constants/icons' // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types' // eslint-disable-line no-unused-vars
import { Field } from 'redux-form'
import FormTextField from 'components/form/FormTextField'
import ListItem from '@material-ui/core/ListItem' // do not use custom component in form
import CenterBox from 'components/general/CenterBox'
import Button from '@material-ui/core/Button'
import { required, validateEmail } from 'utils/form'

function LoginForm({ classes, pristine, submitting, handleSubmit }) {
  
  return (
    <form className={classes.box} onSubmit={handleSubmit}>
      <ListItem alignItems="flex-start">
        <Field
          autoFocus
          name="email"
          component={FormTextField}
          autoComplete="email"
          label="Email:"
          validate={[required, validateEmail]}
        />
      </ListItem>
      <ListItem alignItems="flex-start">
        <Field
          name="password"
          component={FormTextField}
          autoComplete="current-password"
          label="Password:"
          type="password"
          validate={required}
        />
      </ListItem>
      <br/>
      <CenterBox>
        <Button
          color="primary"
          type="submit"
          variant="contained"
          className={classes.button}
          disabled={pristine || submitting}
        >
          <icons.VpnKeyIcon/>&nbsp;
          <b>{submitting ? 'Loading' : 'Login'}</b>
        </Button>
      </CenterBox>
      <br/>
    </form>
  )
}

LoginForm.propTypes = {
  pristine: PropTypes.bool.isRequired, // from enhancer (reduxForm)
  submitting: PropTypes.bool.isRequired, // from enhancer (reduxForm)
  handleSubmit: PropTypes.func.isRequired // from enhancer (reduxForm - calls onSubmit)
}

export default LoginForm
