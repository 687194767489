
const styles = (theme) => ({
  ...theme.global,
  box: {
    backgroundColor: theme.palette.secondary.main
  },
  text: {
    fontWeight: 'bold',
  },
  icon: {
    marginTop: 8,
    marginBottom: -8,
  }
})
export default styles
